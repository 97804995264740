<template>
  <div class=" mt-2">
    <b-card
      class="mb-0"
      :title="$t('settings.cost_center.titles.manage_cost_centers')"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>

        
            <b-col v-if="$Can('add_cost_center')" cols="12" md="3">

           

              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="defaultCostCenters" class="mr-2">
                  <span class="text-nowrap">{{
                    $t("settings.cost_center.buttons.default_cost_center")
                  }}</span>
                </b-button>

                <b-button variant="success" @click="addCostCenter">
                  <span class="text-nowrap">{{
                    $t("settings.cost_center.buttons.create_new_cost_center")
                  }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative p-2"
          :items="allCostCenters"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(index)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.index + 1 }}</strong>
              </span></b
            >
          </template>
          <!-- Column: CostCenter -->
          <template #cell(AccountName)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountName ? data.item.AccountName : "N/A"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Actions -->
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      {{ /*cost_center create modal*/ }}
      <b-modal
        id="modal-prevent-closing"
        v-model="costCenterCreateModal"
        :title="$t('settings.cost_center.titles.create_new_cost_center')"
        @hidden="resetCostCenterCreateModal"
        size="md"
        ok-title=""
        :ok-title="$t('settings.cost_center.buttons.create')"
        :cancel-title="$t('settings.cost_center.buttons.cancel')"
        @ok="createCostCenter"
      >
        <b-overlay :show="createCostCenterLoading" rounded="sm" no-fade>
          <b-container>
            <b-form>
              <validation-observer ref="createCostCenterValidation">

              <b-row class="mt-2">
                <b-col cols="12" xl="12" md="12">
                    <!-- cost_center name -->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('settings.cost_center.titles.cost_center_name')
                        "
                        rules="required|min:3"
                      >
                        <b-form-input
                          id="cost_centername"
                          v-model="newCostCenter"
                          name="cost_center-name"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="
                            $t('settings.cost_center.titles.cost_center_name')
                          "
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <b-form-group label-for="local">
                    <validation-provider
                      name="local"
                      #default="{ errors }"
                      rules="required"
                    >
                      <v-select
                        v-model="costCenterLocal"
                        :label="$t('account_creation.account_form.holder_type')"
                        id="Locals"
                        :clearable="false"
                        :taggable="true"
                        :options="optLocals"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>

            </b-form>
          </b-container>
          <template #overlay v-if="createCostCenterLoading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{
                  $t(
                    "settings.cost_center.messages.cost_center_creating_message"
                  )
                }}
              </p>
            </div>
          </template>
        </b-overlay>
        {{/****END*** cost_center create modal*/}}
      </b-modal>





      <b-modal
        id="modal-prevent-closing"
        v-model="updateDefaultModal"
        :title="$t('settings.cost_center.titles.default_cost_center')"
        @hidden="resetUpdateDefaultModal"
        size="md"
        ok-title=""
        :ok-title="$t('settings.cost_center.buttons.update')"
        :cancel-title="$t('settings.cost_center.buttons.cancel')"
        @ok="updateDefault"
      >
        <b-overlay :show="updateDefaultLoading" rounded="sm" no-fade>
          <b-container>
            <b-form>
       
              <validation-observer ref="updateDefaultsValidation">


              <b-row class="mt-2">
                <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <b-form-group label-for="local">
                    <validation-provider
                      name="local"
                      #default="{ errors }"
                      rules="required"
                    >
                    Equity Default Cost Center
                      <v-select
                        v-model="equityDefault"
                        id="Locals"
                        :clearable="false"
                        :taggable="true"
                        :options="optEquityDefaults"
                        
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <b-form-group label-for="local">
                    <validation-provider
                      name="local"
                      #default="{ errors }"
                      rules="required"
                    >
                    Bond Default Cost Center
                      <v-select
                        v-model="bondDefault"
                        id="Locals"
                        :clearable="false"
                        :taggable="true"
                        :options="optBondDefaults"
                        
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <b-form-group label-for="local">
                    <validation-provider
                      name="local"
                      #default="{ errors }"
                      rules="required"
                    >
                    Fx Default Cost Center
                      <v-select
                        v-model="fxDefault"
                        id="Locals"
                        :clearable="false"
                        :taggable="true"
                        :options="optFXDefaults"
                        
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                  <b-form-group label-for="local">
                    <validation-provider
                      name="local"
                      #default="{ errors }"
                      rules="required"
                    >
                    Transfers Default Cost Center
                      <v-select
                        v-model="transferDefault"
                        id="Locals"
                        :clearable="false"
                        :taggable="true"
                        :options="optTransferDefaults"             
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
           
            </validation-observer>

            </b-form>
          </b-container>
          <template #overlay v-if="updateDefaultLoading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{
                  $t(
                    "settings.cost_center.messages.cost_center_creating_message"
                  )
                }}
              </p>
            </div>
          </template>
        </b-overlay>
        {{/****END*** cost_center create modal*/}}
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      allCostCenters: [],
      optLocals: ["Global", "Equity", "Bond", "FX", "Transfers"],
      costCenterLocal: "Global",
      updateDefaultModal: false,
      updateDefaultLoading: false,
      equityDefault: null,
      bondDefault: null,
      fxDefault: null,
      transferDefault: null,
      optEquityDefaults: [],
      optBondDefaults: [],
      optFXDefaults: [],
      optTransferDefaults: [],


      costCenterCreateModal: false,
      allCreatedCurrencies: [],
      accessLevel: null,
      filterModal: null,
      updatePermloading: false,
      createCostCenterLoading: false,
      newCostCenter: null,

      filterOn: ["CostCenter"],
      tableColumns: [
        {
          key: "index",
          label: "",
        },
        {
          key: "AccountName",
          label: this.$t("settings.cost_center.table.cost_center_name"),
          sortable: true,
        },
        // { key: "actions", label:this.$t('settings.cost_center.table.actions'), sortable: false },
      ],
      perPage: localStorage.getItem("costCenterShow") ?? 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getCurrencies();
  },
  methods: {
    getCurrencies() {
      this.loading = true;
      store
        .dispatch("settings/fetchCostCenters", this.user)
        .then((res) => {
          this.allCostCenters = res.data;
          this.totalRows = res.data.length;

          res.data.forEach(element => {

            if(element.Local == 'Equity' || element.Local =='Global'){
              this.optEquityDefaults.push(element.AccountName)
            }
            if(element.Local == 'Bond' || element.Local =='Global'){
              this.optBondDefaults.push(element.AccountName)
            }
            if(element.Local == 'FX' || element.Local =='Global'){
              this.optFXDefaults.push(element.AccountName)
            }
            if(element.Local == 'Transfers' || element.Local =='Global'){
              this.optTransferDefaults.push(element.AccountName)
            }
            
          });

          store
        .dispatch("settings/getDefaultCostCenters")
        .then((res) => {
          this.equityDefault= res.data.equityDefault;
          this.bondDefault= res.data.bondDefault;
          this.fxDefault= res.data.fxDefault;
          this.transferDefault= res.data.transferDefault;


        })
          


          this.loading = false;

        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    addCostCenter() {
      this.costCenterCreateModal = true;
    },
    createCostCenter(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createCostCenterValidation.validate().then((success) => {
        if (success) {
          this.createCostCenterLoading = true;
          store
            .dispatch("settings/checkExistingCostCenter", {
              AccountName: this.newCostCenter.toUpperCase(),
            })
            .then((res) => {
              if (res.data.existing == 1) {
                this.existingCostCenterMessage();
                this.createCostCenterLoading = false;
              } else {
                store
                  .dispatch("settings/createNewCostCenter", {
                    AccountName: this.newCostCenter.toUpperCase(),
                    Local : this.costCenterLocal
                  })
                  .then((res) => {
                    this.createCostCenterLoading = false;
                    this.resetCostCenterCreateModal();
                    this.getCurrencies();
                    this.createCostCenterToastMessage();
                    this.costCenterLocal =  "Global";


                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    resetCostCenterCreateModal() {
      this.costCenterCreateModal = false;
      this.allCreatedCurrencies = [];
      this.newCostCenter = null;
      this.createCostCenterLoading = false;
    },
    deleteCostCenter(cost_center) {
      const self = this;

      this.$swal({
        title:
          this.$t("settings.cost_center.titles.are_you_sure_delete") +
          ` ${cost_center.AccountName}`,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t(
          "settings.cost_center.buttons.yes_delete_it"
        ),
        cancelButtonText: this.$t("settings.cost_center.buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-success mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("settings/deleteCostCenter", {
              user: this.user,
              cost_center: cost_center.AccountName,
            })
            .then((res) => {
              if (res.data.info == true) {
                self.getCurrencies();
                self.loading = false;
                self.$swal.fire({
                  icon: "success",
                  title: self.$t("settings.cost_center.titles.deleted"),
                  text: self.$t(
                    "settings.cost_center.messages.cost_center_has_been_delete"
                  ),
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                });
              } else {
                this.costCenterNotFoundMessage();
                self.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },



    defaultCostCenters() {
      this.updateDefaultModal = true;
    },
    updateDefault(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.updateDefaultsValidation.validate().then((success) => {
        if (success) {
          this.updateDefaultLoading = true;
        
                store
                  .dispatch("settings/updateDefaultCostCenter", {
                    equityDefault: this.equityDefault,
                    bondDefault: this.bondDefault,
                    fxDefault: this.fxDefault,
                    transferDefault: this.transferDefault,
                  })
                  .then((res) => {
                    this.updateDefaultModal = false;
                    this.updateDefaultLoading = false;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
           
        }
      });
    },
    resetUpdateDefaultModal() {
      this.updateDefaultModal = false;
      this.updateDefaultLoading = false;
    },


    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.cost_center.messages.database_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    existingCostCenterMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.cost_center.messages.cost_center_is_exist"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    costCenterNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.cost_center.messages.cost_center_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createCostCenterToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "settings.cost_center.messages.cost_center_create_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("costCenterShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
